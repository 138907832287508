import React from 'react'
import { graphql } from 'gatsby'
import { GoogleMap } from '@accodeing/gatsby-theme-heimr'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import Header from 'components/Header'

import siteMetaData from '../query/siteMetaData'

export default ({ data }) => {
  const { phone, email } = siteMetaData()
  const headerSources = [
    data.smallHeader.childImageSharp.fluid,
    {
      ...data.header.childImageSharp.fluid,
      media: '(min-width: 600px)',
    },
  ]

  return (
    <Layout pageTitle='Kontakt' siteImage={data.header}>
      <Header fluidImage={headerSources} />
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading page__heading'>Kontakt</h1>
          <p>Välkommen att kontakta oss!</p>
          <ul>
            <li>
              <oma-link to={phone} class='phone-link' />
            </li>
            <li>
              <oma-link to={email} class='phone-link' />
              <strong>
                &nbsp;(vid akuta ärenden, kontakta oss alltid via telefon)
              </strong>
            </li>
            <li>Källebergsvägen 14, 432 91 Varberg</li>
          </ul>
          <GoogleMap
            title='Karta för att hitta till NaprapatAkuten'
            placeId='ChIJD8BCALcvUEYREFp0gdXFcC8'
            zoom='12'
            styles={{
              width: '100%',
              height: '500px',
            }}
          />
        </section>
        <section className='section'>
          <Img
            className='full_width_image'
            fluid={data.sheep.childImageSharp.fluid}
          />
        </section>
      </oma-grid-row>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    smallHeader: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, maxHeight: 599, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    header: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sheep: file(relativePath: { eq: "sheep.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
